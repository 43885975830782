<template>
  <div style="width: 100%; font-size: 16px; font-weight: 500;">
    <marquee class="bg-danger text-white" style="padding-top: 4px; padding-bottom: 4px;">
        Cette version d'ekoworking que vous utilisez n'est plus maintenue. 
        Veuillez cliquer sur le lien suivant pour accéder à la nouvelle version : 
        <a class="text-white" style="text-decoration: underline;" 
          href="https://app.ekoworking.com/">
          https://app.ekoworking.com.
        </a>
    </marquee>
    <div class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>
  
      <!-- Left Col -->
  
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      >
        <dark-Toggler class="d-none d-lg-block" />
  
        <div
          v-show="backAlert"
          class="contain ml-4 alert alert-primary p-1"
          v-b-modal.fetch-alerts-modal
        >
          <span class="txt mr-4"> {{ fetchMyAlert }} </span>
        </div>
      </div>
      <div v-if="enterprises.length > 1">
        <b-avatar
          v-b-tooltip.hover
          :title="!switching ? 'Switcher d\'entreprise' : 'Annuler'"
          @click="switching = !switching"
          button
          :icon="!switching ? 'arrow-repeat' : 'x'"
          variant="light"
        ></b-avatar>
        <v-select
          v-if="switching"
          placeholder="entreprise..."
          v-model="enterpriseMultiSelect"
          :options="enterprisesSelectable"
          label="name"
          class="switcher"
          :clearable="false"
        >
        </v-select>
      </div>
  
      <b-navbar-nav class="nav align-items-center ml-auto my-profile">
        <div class="d-flex align-items-center"></div>
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ userInfo.firstname + " " + userInfo.lastname }}
              </p>
              <span class="user-status">{{ userInfo.email }}</span>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="userInfo.picture"
              class="badge-minimal"
              :badge-variant="userInfo.online ? 'success' : 'danger'"
            />
          </template>
          <b-dropdown-item class="d-sm-none">
            <div class="d-block user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ userInfo.firstname + " " + userInfo.lastname }}
              </p>
              <span class="user-status">{{ userInfo.email }}</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-divider class="d-sm-none" />
          <b-dropdown-item
            :to="'/member-profile/'"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
          </b-dropdown-item>
  
          <b-dropdown-divider />
  
          <b-dropdown-item
            @click.prevent="userLogout"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Déconnexion</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- ALERTES MODAL -->
      <b-modal
        ref="my-alert-modal"
        id="fetch-alerts-modal"
        centered
        modal-class="modal-primary"
        title="Liste des alertes"
        hide-footer
        size="lg"
      >
        <b-overlay :show="loading" rounded="sm">
          <b-table
            v-show="showAlertWindow"
            striped
            :items="alerts"
            :fields="alertFields"
          >
            <template #cell(action)="data">
              <b-row v-if="data.item.user.id == userInfo.id">
                <b-col md="3" class="mr-1"
                  ><button
                    @click="editWindow(data)"
                    class="mr-1 btn btn-icon btn-outline-primary rounded-circle"
                  >
                    <feather-icon icon="EditIcon" size="18" /></button
                ></b-col>
                <b-col md="3"
                  ><button
                    class="mr-1 btn btn-icon rounded-circle btn-outline-danger"
                    @click="deleteThisAlert(data.item.id)"
                  >
                    <feather-icon icon="TrashIcon" size="18" /></button
                ></b-col>
              </b-row>
            </template>
          </b-table>
        </b-overlay>
        <div v-show="editAlertWindow">
          <validation-observer ref="sendAlertForm">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <label for="">Message :</label>
                    <b-form-textarea placeholder="Text" v-model="alert.message" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-input-group>
                    <template #prepend style="border-radius: 10px">
                      <b-input-group-text>Debut :</b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="alert.start_date"
                      type="datetime-local"
                      style="border-radius-right: 10px"
                    />
                  </b-input-group>
                </b-col>
                <b-col md="4">
                  <b-input-group>
                    <template #prepend style="border-radius: 10px">
                      <b-input-group-text>Fin :</b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="alert.end_date"
                      type="datetime-local"
                      style="border-radius-right: 10px"
                    />
                  </b-input-group>
                </b-col>
                <b-col cols="auto">
                  <div style="position: absolute; left: 90%; top: 25%">
                    <b-row>
                      <b-col md="3" class="mr-4">
                        <b-button
                          variant="gradient-light"
                          class="add-story-btn btn-icon btn-light"
                          @click="cancelBtn"
                        >
                          Annuler
                        </b-button>
                      </b-col>
                      <b-col md="3">
                        <b-button
                          variant="gradient-primary"
                          class="add-story-btn btn-icon"
                          @click="editThisAlert"
                        >
                          Modifier
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AppTour from "@/views/AppTour.vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import NotificationDropdown from "./NotificationDropdown.vue";
import { mapState, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  data() {
    return {
      enterprisesSelectable: [],
      switching: false,
      enterpriseMultiSelect: null,
      loading: false,
      steps: [
        {
          target: ".my-profile",
          content: "Accéder et modifier votre profil",
          params: {
            placement: "left",
          },
        },
      ],
      backAlert: false,
      alert: {},
      message: "",
      alertFields: [
        { key: "message", label: "Message" },
        { key: "start_date", label: "Debut" },
        { key: "end_date", label: "Fin" },
        { key: "action", label: "" },
      ],
      checked: false,
      myAlert: "",
      myAlertString: "",
      editAlertWindow: false,
      showAlertWindow: true,
    };
  },
  components: {
    NotificationDropdown,
    ValidationObserver,
    ToastificationContent,
    vSelect,
    // Navbar Components
    DarkToggler,
    AppTour,
  },

  mounted() {
    this.fetchAlertsInProgress().then(() => {
      this.backAlert = this.alerts?.length > 0;
    });

    this.userEnterprises().then(() => {
      let currentEnterprise = JSON.parse(
        localStorage.getItem("enterpriseInfo")
      );
      this.enterprisesSelectable = this.enterprises.filter(
        (item) => item.id != currentEnterprise.id
      );
    });
  },

  watch: {
    enterpriseMultiSelect(val) {
      this.switchEnterprise(val.id).then(() => {
        this.$router.push("/");
        window.location.reload();
      });
    },
  },

  methods: {
    deleteThisAlert(data) {
      this.loading = true;
      this.deleteAlert(data).then((e) => {
        this.loading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Alerte supprimée avec succès",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      });
    },

    editWindow(data) {
      this.showAlertWindow = false;
      this.showAlert(data.item.id).then((e) => {
        this.editAlertWindow = true;
        this.alert = data.item;
      });
    },

    cancelBtn() {
      this.editAlertWindow = false;
      this.showAlertWindow = true;
    },

    async editThisAlert() {
      const valid = await this.$refs.sendAlertForm.validate();
      if (!valid) return;
      this.loading = true;
      this.updateAlert(this.$formData(this.alert))
        .then((r) => {
          this.showAlertWindow = true;
          this.loading = false;
          this.editAlertWindow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alerte envoyée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = true;
          this.errorModal = true;
          this.errors = error.response;
        });
    },

    async userLogout() {
      await this.logout()
        .then(() => {
          this.$router.push("/login");
        })
        .catch((e) => {});
    },

    ...mapActions("auth", ["logout", "userEnterprises", "switchEnterprise"]),
    ...mapActions("alert", [
      "fetchAlertsInProgress",
      "showAlert",
      "updateAlert",
      "deleteAlert",
    ]),
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isOnline() {
      if (this.userInfo.online == true) {
        return (this.checked = true);
      }
    },
    fetchMyAlert() {
      let myVar = "";

      for (let i = 0; i < this.alerts?.length; i++) {
        if (this.alerts?.length != null) {
          myVar +=
            this.alerts[i].user.lastname +
            " " +
            this.alerts[i].user.firstname +
            " a écrit : " +
            this.alerts[i].message +
            (i == this.alerts?.length - 1 ? "" : " - ");
        }
      }
      return myVar;
    },

    ...mapState("auth", ["userInfo", "enterprises"]),
    ...mapState("alert", ["alerts"]),
  },
};
</script>

<style>
.contain {
  overflow: hidden;
  display: flex;
  margin-top: 6px;
  /* Fonctionne avec toutes les tailles */
  width: 65%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  /* pour rotate on enlève le translate et on rajoute le rotate */
  /* transform:  rotate(90deg); */
}
.txt {
  white-space: nowrap;
  font-size: 120%;
  animation: scrollTxt 40s linear infinite;
}

@keyframes scrollTxt {
  0% {
    transform: translate(+100%, 0%);
  }
  100% {
    transform: translate(-100%, 0%);
  }
}

.switcher {
  width: 200px;
  margin-left: 4px;
}

@media only screen and (min-device-width: 500px) {
  .switcher {
    display: inline-block !important;
  }
}

.btn .b-icon.bi {
  color: black !important;
}
</style>
