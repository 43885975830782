import store from "@/store";
function member_only() {
  let userInfo = store.state.auth.userInfo;

  return userInfo?.role_id != 1;
}

function any() {
  return true;
}
function admin_only() {
  let userInfo = store.state.auth.userInfo;
  return userInfo?.role_id == 1;
}
export { member_only, any, admin_only };
