import { admin_only, any, member_only } from "./rules";

export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    visibleTo: any()
  },
  {
    title: 'Gestion des tâches',
    route: 'tasks',
    icon: 'ListIcon',
    visibleTo: any()
  },
  {
    title: 'Bilan du jour',
    route: 'activities',
    icon: 'ActivityIcon',
    visibleTo: any()
  },

  {
    title: 'Projets',
    route: 'projects',
    icon: 'FileIcon',
    visibleTo: any()

  },
  // {
  //   title: 'Projets Clients',
  //   route: 'projet-client',
  //   icon: 'FolderIcon',
  // },
  {
    title: 'Feuille de temps',
    route: 'time-sheet',
    icon: 'ClockIcon',
    visibleTo: any()
  },
  {
    title: 'Collaborateurs',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Liste des collaborateurs ',
        route: 'user-list',
        visibleTo: any(),
      },

      {
        title: 'Feuilles de temps',
        route: 'enterprise-timesheets',
        visibleTo: any()
      },

      {
        title: 'Congés et absences ',
        route: 'absence-validations',
        visibleTo: admin_only()
      },

    ]
  },
  {
    title: 'Permissions',
    route: 'permissions',
    icon: 'KeyIcon',
    visibleTo: member_only()
  },
  /*  {
     title: 'Discussion',
     route: 'chat',
     icon: 'MessageSquareIcon',
   }, */
  /*  {
     title: 'Mes notes',
     route: 'my_notes',
     icon: 'StarIcon',
   }, */

  {
    title: 'Permissions',
    route: 'permissions-management',
    icon: 'KeyIcon',
    visibleTo: admin_only()
  },

  {
    title: 'Services ',
    route: 'services',
    icon: 'BriefcaseIcon',
    visibleTo: admin_only()
  },
  {
    title: 'Mes clients',
    route: 'admin-customer',
    icon: 'GridIcon',
    visibleTo: any()
  },
  {
    title: 'Facturation',

  },

  {
    title: 'Facturation',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Devis',
        route: 'admin-devis',
        visibleTo: any()
      },
      {
        title: 'Factures',
        route: 'admin-invoices',
        visibleTo: any()
      },
      {
        title: 'Factures d\'acompte',
        route: 'admin-advance-payments',
        visibleTo: any()
      },
      {
        title: 'Factures Récurrentes',
        route: 'admin-recurring-invoices',
        visibleTo: any()
      }, {
        title: 'Factures d\'avoir',
        route: 'admin-cancelled-invoices',
        visibleTo: any()
      },
      {
        title: 'Tâches non facturées',
        route: 'billable-task-not-billed',
        visibleTo: any()
      },
    ],
  },
  {
    title: 'Statistiques ',
    route: 'admin-statistiques',
    icon: 'BarChart2Icon',
    visibleTo: admin_only()
  },
  {
    title: 'Aide ',
    route: 'contact-us',
    icon: 'HelpCircleIcon',
    visibleTo: any()
  },


]
